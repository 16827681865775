import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//Components
import { ProfilePicturesComponent } from './common/component/profile-pictures/profile-pictures.component';
import { SearchResultsComponent } from './common/component/search-results/search-results.component';
import { ViewInfoComponent } from './common/component/view-info/view-info.component';
import { AuthGuard, CanAuthLoginGuard } from './common/guards/auth.guard';
import { AboutMemorialComponent } from './private/about-memorial/about-memorial.component';
import { AddPicturesVideosComponent } from './private/add-pictures-videos/add-pictures-videos.component';
import { CareerHobbiesComponent } from './private/career-hobbies/career-hobbies.component';
import { CongratulationsComponent } from './private/congratulations/congratulations.component';
import { FamilyMemoriesComponent } from './private/family-memories/family-memories.component';
import { HomeComponent } from './private/home/home.component';
import { LandingPageComponent } from './public/component/landing-page/landing-page.component';
import { PaymentPurchaseMemoryCodeComponent } from './public/component/payment-purchase-memory-code/payment-purchase-memory-code.component';
import { ResetPasswordComponent } from './public/component/reset-password/reset-password.component';
import { BasicDetailsComponent } from './private/basic-details/basic-details.component';
import { MemberListComponent } from './private/member-list/member-list.component';
import { AboutUsComponent } from './public/component/about-us/about-us.component';
import { ContactUsComponent } from './public/component/contact-us/contact-us.component';
import { TermsUseComponent } from './public/component/terms-use/terms-use.component';
import { PrivacyPolicyComponent } from './public/component/privacy-policy/privacy-policy.component';
import { ScanQrComponent } from './public/scan-qr/scan-qr.component';
import { PageNotFoundComponent } from './common/component/page-not-found/page-not-found.component';
import { AddGuestBookComponent } from './private/add-guest-book/add-guest-book.component';
import { AddGuestMessageComponent } from './private/add-guest-message/add-guest-message.component';
import { GuestbookRequestComponent } from './private/guestbook-request/guestbook-request.component';
import { GuestbookGalleryComponent } from './private/guestbook-gallery/guestbook-gallery.component';
import { UnsubscribeComponent } from './public/component/unsubscribe/unsubscribe.component';
import { UpgradePlansComponent } from './private/upgrade-plans/upgrade-plans.component';
import { EmailVerifyComponent } from './public/component/email-verify/email-verify.component';
import { MemoryCodePageComponent } from './private/memory-code-page/memory-code-page.component';
import { LikeCommentComponent } from './common/component/like-comment/like-comment.component';
import { InviteCoOwnerComponent } from './public/component/invite-co-owner/invite-co-owner.component';
import { AddCoOwnerDetailsComponent } from './public/component/add-co-owner-details/add-co-owner-details.component';
import { FaqComponent } from './public/component/faq/faq.component';
import { SendMessageUserComponent } from './private/send-message-user/send-message-user.component';
import { CreateItineraryComponent } from './public/component/create-itinerary/create-itinerary.component';
import { ViewItineraryComponent } from './public/component/view-itinerary/view-itinerary.component';
import { SendMessageGuestComponent } from './private/send-message-guest/send-message-guest.component';


const routes: Routes = [
  { path: '', redirectTo: 'landing-page', pathMatch: 'full'},
  { path: 'landing-page', component: LandingPageComponent, pathMatch: 'full', canActivate: [CanAuthLoginGuard] },
  { path: 'scan-qr', component: ScanQrComponent, pathMatch: 'full', canActivate: [CanAuthLoginGuard] },
  { path: 'search-results', component: SearchResultsComponent, pathMatch: 'full' },
  { path: 'unsubscribe', component: UnsubscribeComponent, pathMatch: 'full' },
  { path: 'email-verify', component: EmailVerifyComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'terms-condition', component: TermsUseComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
  { path: 'about-us', component: AboutUsComponent, pathMatch: 'full' },
  { path: 'faq', component: FaqComponent, pathMatch: 'full' },
  { path: 'view-info', component: ViewInfoComponent, pathMatch: 'full', canActivate: [CanAuthLoginGuard] },
  { path: 'view-memories', component: ProfilePicturesComponent, pathMatch: 'full', },
  { path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full', canActivate: [CanAuthLoginGuard] },
  { path: 'payment', component: PaymentPurchaseMemoryCodeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'invite-co-owner', component: InviteCoOwnerComponent, pathMatch: 'full' },
  { path: 'create-itinerary/:id', component: CreateItineraryComponent, pathMatch: 'full' },
  { path: 'view-itinerary/:id', component: ViewItineraryComponent, pathMatch: 'full' },
  { path: 'add-co-owner-details', component: AddCoOwnerDetailsComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'add-loved-one', component: BasicDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'update-info', component: BasicDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'update-info/:id', component: BasicDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'about-memorial', component: AboutMemorialComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'about-memorial/:id', component: AboutMemorialComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'about-careers-hobbies', component: CareerHobbiesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'about-careers-hobbies/:id', component: CareerHobbiesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'about-family-memories', component: FamilyMemoriesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'about-family-memories/:id', component: FamilyMemoriesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'add-pictures-videos', component: AddPicturesVideosComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'add-pictures-videos/:id', component: AddPicturesVideosComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'congratulations', component: CongratulationsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'add-guestBook', component: AddGuestBookComponent, pathMatch: 'full' },
  { path: 'add-guest-message', component: AddGuestMessageComponent, pathMatch: 'full' },
  { path: 'guestbook-gallery/:id', component: GuestbookGalleryComponent, pathMatch: 'full' },
  { path: 'guestbook-gallery', component: GuestbookGalleryComponent, pathMatch: 'full' },
  { path: 'send-message-user/:id', component: SendMessageUserComponent, pathMatch: 'full' },
  { path: 'send-message-guest', component: SendMessageGuestComponent, pathMatch: 'full' },
  { path: 'guest-request/:id', component: GuestbookRequestComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'memory-code/:id', component: MemoryCodePageComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'upgrade-plans', component: UpgradePlansComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'view-information', component: ViewInfoComponent, pathMatch: 'full' },
  { path: 'view-information/:id', component: ViewInfoComponent, pathMatch: 'full' },
  { path: 'view-all-memories', component: ProfilePicturesComponent, pathMatch: 'full', },
  { path: 'like-comment', component: LikeCommentComponent, pathMatch: 'full', },
  { path: 'members-list', component: MemberListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({

  imports: [RouterModule.forRoot(routes, { useHash: true })
  ],
 
  exports: [RouterModule],
})
export class AppRoutingModule { }
