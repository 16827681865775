<header [ngClass]="!showBrandLogo || !router.url.includes('landing-page') ? 'scroll-bg' : ' '">
    <a (click)="showNav=false;">
        <div class="menu-overlay scroll-stop" *ngIf="showNav"></div>
    </a>
    <nav class="navbar fixed-top navbar-expand-md navbar-light" [ngClass]="{
        'add-height': showNav,
        'header-bg-remove':router.url.includes('landing-page')
      }" aria-controls="navbarTogglerDemo02"
        *ngIf="!(router.url.includes('payment')) && !(router.url.includes('invite-co-owner')) && !(router.url.includes('add-co-owner-details')) && !(router.url.includes('view-itinerary'))">
        <div class="container">
            <a class="navbar-brand"
                [ngClass]="!(router.url.includes('add-guestBook') || router.url.includes('add-guest-message') || router.url.includes('send-message-guest'))?'':'navbar-brand-hide active'"
                [hidden]="showNav" (click)="goDashboard()"><img src="../assets/images/brand-icon.svg" alt="brand-icon"
                    *ngIf="!showBrandLogo || !router.url.includes('landing-page')"></a>
            <button
                *ngIf="!(router.url.includes('add-guestBook') || router.url.includes('add-guest-message') || router.url.includes('send-message-guest') || router.url.includes('view-itinerary') || router.url.includes('create-itinerary'))"
                class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
                (click)="navToggle()">
                <span class="navbar-toggler-icon" [hidden]="showNav"><img src="assets/images/navbar-toggle-icon.svg"
                        alt="navbar-toggle-icon"></span>
            </button>
            <div class="collapse navbar-collapse plant-before-login"
                [ngClass]="!showNav?'collapse navbar-collapse':'collapse navbar-collapse show'" id="navbarTogglerDemo02"
                *ngIf="!(router.url.includes('reset') || router.url.includes('add-guestBook') || router.url.includes('add-guest-message') || router.url.includes('create-itinerary') || router.url.includes('view-itinerary') || router.url.includes('send-message-guest'))">
                <ul class="navbar-nav ms-auto mb-lg-0 nav-align-center"
                    *ngIf="!(router.url.includes('payment')) && !(router.url.includes('add-co-owner-details'))">
                    <li class="nav-item" *ngIf="isLoggedIn">
                        <a class="nav-link"
                            [ngClass]="{ 'active-link': currentRoute != '/faq' && currentRoute != '/about-us' && currentRoute != '/contact-us' && currentRoute != '/privacy-policy' && currentRoute != '/terms-condition' }"
                            aria-current="page" (click)="goDashboard();showNavBar()">Home</a>
                    </li>
                    <li class="nav-item" *ngIf="isLoggedIn">
                        <a class="nav-link" aria-current="page" (click)="openSearch();showNavBar()">
                            <img class="serach-icon-style" src="./../../../../assets/images/search-link-img.svg"
                                alt="delete" />
                            Search
                        </a>
                    </li>
                    <li class="nav-item about-us-link" *ngIf="isLoggedIn">
                        <a class="nav-link" [ngClass]="{ 'active-link': currentRoute === '/about-us' }"
                            aria-current="page" (click)="goToAboutUs();showNavBar()">About Us</a>
                    </li>
                    <li class="nav-item about-us-link" *ngIf="isLoggedIn">
                        <a class="nav-link" [ngClass]="{ 'active-link': currentRoute === '/faq' }" aria-current="page"
                            (click)="goToFAQ();showNavBar()">FAQs</a>
                    </li>
                    <ng-container *ngIf="!isLoggedIn">
                        <button (click)="plantTree()" class="btn btn-outline-primary add-plant-btn">
                            Plant a Tree in Remembrance
                            <svg width="31" height="23" viewBox="0 0 31 23" xmlns="http://www.w3.org/2000/svg">
                                <g fill="#885D40" fill-rule="nonzero">
                                    <path
                                        d="M17.646 14.147V5.973a5.424 5.424 0 0 1-5.031-.342 5.395 5.395 0 0 1-2.564-4.332.487.487 0 0 1 .223-.434 5.427 5.427 0 0 1 5.413-.27 5.4 5.4 0 0 1 2.936 4.55l-.34-1.205.34-.495a5.412 5.412 0 0 1 7.929-.828c.13.115.19.292.155.463a5.428 5.428 0 0 1-3.584 4.051 5.396 5.396 0 0 1-1.712.278 5.47 5.47 0 0 1-2.787-.765v7.503h-.978zm8.039-10.982a4.446 4.446 0 0 0-4.132-.7 4.43 4.43 0 0 0-2.872 3.043 4.47 4.47 0 0 0 4.13.7 4.454 4.454 0 0 0 2.874-3.043zM15.24 1.463a4.473 4.473 0 0 0-4.19.074 4.425 4.425 0 0 0 2.382 3.439 4.447 4.447 0 0 0 4.19-.073 4.451 4.451 0 0 0-2.382-3.44z" />
                                    <path
                                        d="M28.054 9.757a1166.444 1166.444 0 0 0-2.973 2.214c-.414.312-1.765 1.54-3.369 1.961-2.005-.24-5.107-.57-8.075-1.638l-1.378-.687a10.25 10.25 0 0 0-4.553-1.07 10.128 10.128 0 0 0-7.199 2.974L0 14.017l.511 1.018a.583.583 0 0 0 .202-.13l.595-.593a8.997 8.997 0 0 1 6.398-2.642 9.103 9.103 0 0 1 4.044.953l2.517 1.258c.05.025.105.042.16.051 2.35.364 4.109.644 5.28.839.167.027.415.082.744.163.185.03.32.19.32.377a1.465 1.465 0 0 1-1.466 1.46c-1.267 0-2.532-.09-3.786-.269a30.006 30.006 0 0 0-4.21-.298h-.197v1.134h.197c1.355 0 2.708.096 4.05.287 1.307.186 2.626.28 3.946.28a2.6 2.6 0 0 0 2.603-2.596 1.486 1.486 0 0 0-.016-.183l.636-.056a.57.57 0 0 0 .284-.106l5.91-4.289a.72.72 0 0 1 1.142.578 2.31 2.31 0 0 1-.486 1.413L26.076 16.9l-7.42 4.162a6.396 6.396 0 0 1-4.958.531l-5.495-1.687a6.913 6.913 0 0 0-5.805.844l.63.944a5.752 5.752 0 0 1 4.84-.704l5.495 1.687a7.55 7.55 0 0 0 5.853-.627l7.516-4.218a.552.552 0 0 0 .171-.146l3.373-4.324A3.445 3.445 0 0 0 31 11.253a1.854 1.854 0 0 0-2.946-1.496z" />
                                </g>
                            </svg>
                        </button>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ 'active-link': currentRoute === '/about-us' }"
                                routerLink="/about-us" target="_blank">About
                                Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ 'active-link': currentRoute === '/faq' }" routerLink="/faq"
                                target="_blank">FAQs
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="openSignIn();showNavBar()">Sign
                                In</a>
                        </li>
                    </ng-container>
                    <div class="dropdown profile-dropdown" *ngIf="isLoggedIn">
                        <div class="profile-user-img">
                            <img [src]="userData.user.profile_picture || 'assets/images/user-img.svg'" alt="user-img">
                        </div>
                        <a class="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            My Profile
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" (click)="openEditProfile();showNavBar()">Edit
                                    Profile</a></li>
                            <li><a class="dropdown-item" (click)="openChangePassword();showNavBar()">Change
                                    Password</a></li>
                            <li><a class="dropdown-item" (click)="manageCoOwners();showNavBar()">Manage Sub-Owners</a>
                            </li>

                            <li><a class="dropdown-item" (click)="logout();showNavBar()">Logout</a>
                            </li>
                        </ul>
                    </div>

                </ul>
                <form class="d-flex header-purchase-form" *ngIf="!isLoggedIn">
                    <button class="btn btn-primary header-purchase-btn" type="button"
                        (click)="openSignUp();showNavBar()">Create
                        Account</button>
                </form>
                <div class="navbar-close-btn d-none">
                    <button type="button" class="btn-close ms-auto" (click)="showNav=false; onClose()"></button>
                </div>
            </div>


            <div *ngIf="router.url.includes('add-guestBook') || router.url.includes('add-guest-message') || router.url.includes('send-message-guest')"
                class="navbar-collapse justify-content-between header-collapse-between">
                <div class="plant-btn-style-bg">
                    <div class="plant-btn-style">
                        <button (click)="plantTree()" class="btn btn-outline-primary add-plant-btn">
                            Plant a Tree in Remembrance
                            <svg width="31" height="23" viewBox="0 0 31 23" xmlns="http://www.w3.org/2000/svg">
                                <g fill="#885D40" fill-rule="nonzero">
                                    <path
                                        d="M17.646 14.147V5.973a5.424 5.424 0 0 1-5.031-.342 5.395 5.395 0 0 1-2.564-4.332.487.487 0 0 1 .223-.434 5.427 5.427 0 0 1 5.413-.27 5.4 5.4 0 0 1 2.936 4.55l-.34-1.205.34-.495a5.412 5.412 0 0 1 7.929-.828c.13.115.19.292.155.463a5.428 5.428 0 0 1-3.584 4.051 5.396 5.396 0 0 1-1.712.278 5.47 5.47 0 0 1-2.787-.765v7.503h-.978zm8.039-10.982a4.446 4.446 0 0 0-4.132-.7 4.43 4.43 0 0 0-2.872 3.043 4.47 4.47 0 0 0 4.13.7 4.454 4.454 0 0 0 2.874-3.043zM15.24 1.463a4.473 4.473 0 0 0-4.19.074 4.425 4.425 0 0 0 2.382 3.439 4.447 4.447 0 0 0 4.19-.073 4.451 4.451 0 0 0-2.382-3.44z" />
                                    <path
                                        d="M28.054 9.757a1166.444 1166.444 0 0 0-2.973 2.214c-.414.312-1.765 1.54-3.369 1.961-2.005-.24-5.107-.57-8.075-1.638l-1.378-.687a10.25 10.25 0 0 0-4.553-1.07 10.128 10.128 0 0 0-7.199 2.974L0 14.017l.511 1.018a.583.583 0 0 0 .202-.13l.595-.593a8.997 8.997 0 0 1 6.398-2.642 9.103 9.103 0 0 1 4.044.953l2.517 1.258c.05.025.105.042.16.051 2.35.364 4.109.644 5.28.839.167.027.415.082.744.163.185.03.32.19.32.377a1.465 1.465 0 0 1-1.466 1.46c-1.267 0-2.532-.09-3.786-.269a30.006 30.006 0 0 0-4.21-.298h-.197v1.134h.197c1.355 0 2.708.096 4.05.287 1.307.186 2.626.28 3.946.28a2.6 2.6 0 0 0 2.603-2.596 1.486 1.486 0 0 0-.016-.183l.636-.056a.57.57 0 0 0 .284-.106l5.91-4.289a.72.72 0 0 1 1.142.578 2.31 2.31 0 0 1-.486 1.413L26.076 16.9l-7.42 4.162a6.396 6.396 0 0 1-4.958.531l-5.495-1.687a6.913 6.913 0 0 0-5.805.844l.63.944a5.752 5.752 0 0 1 4.84-.704l5.495 1.687a7.55 7.55 0 0 0 5.853-.627l7.516-4.218a.552.552 0 0 0 .171-.146l3.373-4.324A3.445 3.445 0 0 0 31 11.253a1.854 1.854 0 0 0-2.946-1.496z" />
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div *ngIf="!router.url.includes('pictureId') && !router.url.includes('add-guestBook')"
                        (click)="goToSendRequest()" class="cursor-pointer margin-close"
                        [ngClass]="{ 'active-link': currentRoute === '/add-guest-message'}">
                        Send Requests</div>
                    <div *ngIf="!router.url.includes('pictureId') && !router.url.includes('add-guestBook')"
                        (click)="goToChatFromGuest()" class="cursor-pointer margin-close private-msg"
                        [ngClass]="{ 'active-link': currentRoute === '/send-message-guest'}">
                        Private Messages
                        <div *ngIf="!router.url.includes('send-message-guest') && newPrivateMessage?.data?.new_messages == true"
                            class="red-circle"></div>
                    </div>
                </div>
                <div class="header-email-cancel">
                    <div *ngIf="!router.url.includes('pictureId')" class="cursor-pointer margin-close">
                        {{guestUserEmail}}</div>
                    <div *ngIf="!router.url.includes('pictureId')" (click)="routeUrl()"
                        class="cursor-pointer cancel-btn">
                        Cancel</div>

                </div>
                <div *ngIf="router.url.includes('pictureId')" (click)="location.back()"
                    class="cursor-pointer cancel-btn">Cancel</div>
            </div>
        </div>
    </nav>
    <div *ngIf="(isLoggedIn && (router.url.includes('payment'))) || (isLoggedIn && (router.url.includes('add-co-owner-details')))"
        class="own-Header">
        <div class="dropdown profile-dropdown">
            <div class="profile-user-img profile-user-display">
                <img [src]="userData.user.profile_picture || 'assets/images/user-img.svg'" alt="user-img">
            </div>
            <a class="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                aria-expanded="false">
                My Profile
            </a>
            <ul class="dropdown-menu payment-dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li><a class="dropdown-item" (click)="openEditProfile();showNavBar()">Edit
                        Profile</a></li>
                <li><a class="dropdown-item" (click)="openChangePassword();showNavBar()">Change
                        Password</a></li>
                <li><a class="dropdown-item" (click)="logout();showNavBar()">Logout</a>
                </li>
            </ul>
        </div>
    </div>
</header>
