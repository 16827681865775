import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../../app/common/interfaces/response';
import { MagicNumbers } from '../../../common/component/app-magic-numbers';
import { AuthService } from '../../../common/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  public isShow = false;
  public showConfirm = false;
  public resetPasswordForm: FormGroup;
  public token;
  public email;
  constructor(public formBuilder: FormBuilder, public activatedRoute: ActivatedRoute, public authService: AuthService, public router: Router, public toastr: ToastrService) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      Validators.maxLength(MagicNumbers.fifteen)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      Validators.maxLength(MagicNumbers.fifteen)]],

    });

    this.activatedRoute.queryParams.subscribe(querParams => {
      if (querParams.verify && querParams.email) {
        this.email = querParams.email;
        this.token = querParams.verify;
      }
    });
  }

  get password() { return this.resetPasswordForm.get('password'); }
  get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }

  /**
   * API Call for reseting the password
  */
  onSubmit() {
    if (this.resetPasswordForm.valid && this.resetPasswordForm.value.password === this.resetPasswordForm.value.confirmPassword) {
      const payload = {
        email: this.email,
        token: this.token,
        new_password: this.resetPasswordForm.value.password,
        confirm_password: this.resetPasswordForm.value.confirmPassword
      };
      this.authService.resetPassword(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success('Password successfully updated');
          this.router.navigate(['/']);
        }
      });
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

}
