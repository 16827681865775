<section class="view-info-section co-owner-section chat-message-section res-pt-0">
    <div class="container">
        <div class="view-itinerary-header">
            <div class="container">
                <a class="navbar-brand"><img src="../assets/images/brand-icon.svg" alt="brand-icon"></a>
                <div class="skip-btn-wrap">
                    <button (click)="downloadItinerary()" class="btn btn-primary extra-padding">Download
                        Itinerary</button>
                    <button (click)="goToMember()" class="btn link-btn btn-border cancel-blue">Close</button>
                </div>
            </div>
        </div>

        <div class="first-border">
            <div class="header-img-infinity">
                <img src="assets/images/viewinfo-infinity.svg" alt="viewinfo-infinity">
            </div>

            <div class="footer-img-heart">
                <img src="assets/images/viewinfo-heart.svg" alt="viewinfo-heart">
            </div>

            <div class="second-border view-etinerary-wrap">
                <div class="member-img-section">
                    <div class="title-right-sec new-input-file-wrap img-fluid member-img">
                        <div class="add-picture-section">
                            <img *ngIf="memberDetails?.profile_picture" [src]="memberDetails?.profile_picture"
                                alt="member-title-image" class="view-info-img">
                            <img *ngIf="!memberDetails?.profile_picture" src='assets/images/user-img.svg'
                                alt="member-title-image" class="default-info-img">
                        </div>

                    </div>
                    <img src="assets/images/rose-view.svg" alt="flowers" class="flower-img">


                </div>
                <div class="view-etinerary-inner">
                    <p class="view-etinerary-des">We invite you to join us at {{memberDetails?.name}}’s final
                        resting place,
                        <span class="highlight-text">{{itineraryDetails?.interment_place}},
                            {{itineraryDetails?.interment_location}}</span>
                        on <span class="highlight-text">{{itineraryDetails?.interment_ceremony_date}}
                        </span> at <span class="highlight-text">{{itineraryDetails?.interment_ceremony_time}}</span>.
                    </p>
                    <div class="add-more-wrap">
                        <img src="assets/images/location-pin.svg" alt="map" class="map-logo">
                        <button
                            (click)="viewLocationOnMap(itineraryDetails?.interment_latitude, itineraryDetails?.interment_longitude)"
                            type="button" class="btn btn-link edit-btn">See on Map</button>
                    </div>
                </div>
                <div class="view-etinerary-inner">

                    <p *ngIf="itineraryDetails?.celebration_of_life_address" class="view-etinerary-des">You are
                        invited
                        to gather with the family and friends of {{memberDetails?.name}}
                        after the funeral at <span
                            class="highlight-text">{{itineraryDetails?.celebration_of_life_place}},
                            {{itineraryDetails?.celebration_of_life_address}}</span>. The time of
                        gathering will be <span
                            class="highlight-text">{{itineraryDetails?.celebration_of_life_gathering_date}}</span> at
                        <span class="highlight-text">{{itineraryDetails?.celebration_of_life_gathering_time}}</span>.
                        We hope you can join family & friends in this celebration of life.
                    </p>
                    <div *ngIf="itineraryDetails?.celebration_of_life_address" class="add-more-wrap">
                        <img src="assets/images/location-pin.svg" alt="map" class="map-logo">
                        <button
                            (click)="viewLocationOnMap(itineraryDetails?.celebration_of_life_latitude, itineraryDetails?.celebration_of_life_longitude)"
                            type="button" class="btn btn-link edit-btn">See on Map</button>

                    </div>
                </div>



            </div>
        </div>
