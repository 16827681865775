<!-- main section start here -->
<section class="main-section mb-0">
    <div class="container">
        <div class="row main-section-row">
            <div class="col-12 col-md-6 search-col pb-0">
                <div class="author-caption">
                    <img src="../assets/images/logo-home.svg" alt="logo-home" class="big-brand-icon img-fluid"
                        [style.visiblity]="!hideBigBrand?'hidden':''" />
                    <h2>“It's not the length of life, but depth of life”</h2>
                    <p class="heading-caption">-Ralph Waldo Emerson</p>
                </div>
                <div class="search-section">
                    <h3>Search digital memorials of your loved ones.</h3>
                    <button type="button" class="btn btn-border" (click)="isPersonal=true;searchName=null"
                        [ngClass]="isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">Personal
                        Details</button>
                    <button type="button" class="btn btn-border memory-code" (click)="isPersonal=false;searchName=null"
                        [ngClass]="!isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">QR Memory
                        Code
                        Number</button>
                    <form class="search-form">
                        <div class="input-group">
                            <input type="text" class="form-control form-control-search"
                                placeholder="{{isPersonal?'Enter Name':'Enter QR Memory Code Number'}}"
                                [maxlength]="isPersonal?30:12" aria-label="Enter name" aria-describedby="basic-addon2"
                                [(ngModel)]="searchName" (keyup.enter)="goToSearch()" name="searchName">
                            <span class="input-group-text input-search" id="basic-addon2" (click)="goToSearch()"><img
                                    src="../assets/images/search.svg" alt="search"></span>
                        </div>
                        <button type="button" class="btn btn-link" data-bs-toggle="modal"
                            (click)="isPersonal=true;getStates()" data-bs-target="#exampleModal">Advanced
                            Search</button>
                    </form>
                    <div class="rip-image-section">
                        <img src="../assets/images/rip-img.png" alt="rip-img" class="rip-img " />
                        <p class="img-caption"><span class="color-text">Scanning a QR Memory code</span> from your
                            mobile
                            device is another way to search memorial placed on tombstone.</p>
                        <img src="../assets/images/guide-arrow.svg" alt="guide-arrow" class="guide-arrow" />
                        <img src="../../../../assets/images/guide-arrow-left.svg" alt="guide-arrow"
                            class="guide-arrow-left d-none" />
                    </div>
                    <div class="qr-code-section-mobile">
                        <div class="qr-code-des">
                            <span class="qr-code-img-mobile"><img
                                    src="./../../../../assets/images/qr-code-img-mobile.svg" alt="qr-code-img"></span>
                            <span class="qr-code-des-header">Scan QR Memory Code <p>Scan digital memorials of your loved
                                    ones
                                </p></span>

                        </div>
                        <button class="btn btn-primary qr-code-btn-mobile" routerLink="/scan-qr">Scan QR Memory Code
                        </button>
                        <p class="note mt-1">Note: For better user experience, use Chrome or Safari</p>

                        <span class="border-text">OR</span>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-6">
                <!-- carousel start here -->
                <div id="carouselExampleSlidesOnly" class="carousel" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active bg-dark">
                            <img src="../assets/images/heroimage.png" class="d-block w-100 carousel-img"
                                alt="carousel-image" />
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</section>


<section *ngIf="!topAdImage" class="margin-top-ad">
</section>

<section class="ad-section" *ngIf="topAdImage && topAdUrl">
    <div class="ad-mangement-ad-sec" (click)="adClick(topAdId,topAdUrl)">
        <div class="ad-text">Advertisement</div>
        <div class="ad-logo">
            <img [attr.src]="topAdImage" alt="carousel-image" />
        </div>
    </div>
</section>



<!-- memory code section start here -->
<section class="memory-code-section pb-60">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 img-col memory-code-img-col">
                <div class="memory-code-img">
                    <img src="../assets/images/memory-group-img.png" alt="memory-group-img" class="img-fluid" />
                </div>
            </div>
            <div class="col-12 col-md-6 text-col generate-memory-code-col">
                <h1><img src="../assets/images/generate-patters-new.png" width="44" height="43" alt="generate" class="generate-logo" />
                    Generate QR Memory Code for Memorials.</h1>
                <p class="text-para">QR Memory codes allow loved ones to unlock their memorialized webpage and allows
                    others to view and/or contribute. You may also affix the code to a desired memorialized location.
                </p>

                <ul class="list-group ul-dotted-border">
                    <li class="list-group-item"><img src="../assets/images/delete-memory-code.svg" alt="list-img" />
                        Purchase welcome kit and receive QR Memory code</li>
                    <li class="list-group-item"><img src="../assets/images/account-memory-code.svg" alt="list-img" />
                        Create an account with EternallyMe platform</li>
                    <li class="list-group-item"><img src="../assets/images/user-memory-code.svg" alt="list-img" /> Use
                        the QR Memory code to create your personalized memorial to include up to unlimited photos,
                        videos and 5000 words.
                    </li>
                    <li class="list-group-item"><img src="../assets/images/place-memory-code.svg" alt="list-img"
                            class="last-list-item" /> If desired, place the QR Memory code on the memorial or
                        tombstone.
                    </li>
                </ul>
                <div class="service-charge">
                    <div class="price">
                        <p>$195</p>
                    </div>
                    <span>
                        One time and then $14.99 or $24.99 annual charges for a digital memorial page creation for your
                        loved one .
                    </span>
                </div>
                <button class="btn btn-primary" type="button" (click)="openSignUp();showNavBar()">Purchase QR Memory
                    Code</button>

            </div>
        </div>
    </div>
</section>

<section *ngIf="!topAdImage" class="margin-top-ad">
</section>

<section class="ad-section" *ngIf="bottomAdImage && bottomAdUrl">
    <div class="ad-mangement-ad-sec" (click)="adClick(bottomAdId,bottomAdUrl)">
        <div class="ad-text">Advertisement</div>
        <div class="ad-logo">
            <img [attr.src]="bottomAdImage" alt="carousel-image" />
        </div>
    </div>
</section>

<!-- card section start here -->
<section class="memory-code-section card-section">
    <div class="container">
        <div class="row memory-code-row">
            <div class="col-12 col-md-6 text-col">
                <h1>Why Online Memorials?</h1>
                <p class="card-para">We all have loved ones, families, friends or partners who are our entire world. We
                    love them infinitely & have a lifetime of memories with them that should be saved forever, even
                    after our loved ones are no longer with us.</p>
                <ul class="list-group">
                    <li class="list-group-item"><img src="../assets/images/icons-bullets.svg" alt="icons-bullets" />
                        Keep memories alive</li>
                    <li class="list-group-item"><img src="../assets/images/icons-bullets.svg" alt="icons-bullets" />
                        Presents stories about our loved ones</li>
                    <li class="list-group-item"><img src="../assets/images/icons-bullets.svg" alt="icons-bullets" />
                        Safe and secure memories</li>
                    <li class="list-group-item"><img src="../assets/images/icons-bullets.svg" alt="icons-bullets" />
                        Memory preservation</li>
                </ul>
            </div>
            <div class="col-12 col-md-6 img-col card-img-col">
                <div class="card-img">
                    <div class="row card-row">
                        <div class="card-wrapper">
                            <div class="col-md-4">
                                <div class="card landing-card landing-card-1">
                                    <div class="card-img">
                                        <img src="../assets/images/card-img-1.png" class="card-img-top img-fluid"
                                            alt="card-img-1" />
                                    </div>
                                    <div class="card-body text-center">
                                        <h5 class="card-title">Julia Wilson</h5>
                                        <p class="card-text">1980-2012
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card landing-card landing-card-2">
                                    <div class="card-img">
                                        <img src="../assets/images/card-img-2.png" class="card-img-top img-fluid"
                                            alt="card-img-2" />
                                    </div>
                                    <div class="card-body text-center">
                                        <h5 class="card-title">John W Smith</h5>
                                        <p class="card-text">1980-2012
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card landing-card landing-card-3">
                                    <div class="card-img">
                                        <img src="../assets/images/card-img-3.png" class="card-img-top img-fluid"
                                            alt="card-img-3" />
                                    </div>

                                    <div class="card-body text-center">
                                        <h5 class="card-title">Olive Henry</h5>
                                        <p class="card-text">1980-2012</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="memory-code-section card-section solution-sec">
    <div class="container">
        <div class="row memory-code-row">
            <div class="col-12 col-md-5 img-col">
                <div class="memory-code-img">
                    <img src="../assets/images/solution-img.png" alt="solution-img" class="img-fluid" />
                </div>
                <div class="img-caption">We provide a product that allows for families to preserve the memories of their
                    loved ones that starts at the memorial service and carries through to the final resting place and
                    remains in place for eternity. We provide a shared experience during the grieving process that often
                    leaves families feeling isolated.</div>
            </div>
            <div class="col-12 col-md-7 text-col generate-memory-code-col">
                <h1 class="heading-solution">The Solution</h1>
                <p class="solution-para">Our solution is friend and family sourced - web based memorial package</p>
                <p class="solution-para-list">What does this provide:</p>
                <ul class="solution-list-group">
                    <li>Mourners who attend the funeral can scan the QR Memory code that is attached to a tasteful
                        picture frame at the entrance where a guest book would normally be located;</li>
                    <li>It can capture the names and addresses of the visitors in a virtual guestbook;</li>
                    <li>It can allow for loved ones to leave their favorite memories;</li>
                    <li>Family members can sign up for text/email alerts of special days of their loved one such as
                        birthdays and anni- versaries;</li>
                    <li>Mourners can view the loved one’s pictures uploaded by the family;</li>
                    <li>Allows for visitors to upload pictures and videos that the family may not have into a queue for
                        the family to select and then post on the site;</li>
                    <li>Family members can put these memories on a screen for all to view in the funeral home;</li>
                    <li>For those that cannot attend the memorial service, it allows them to also view the memorial and
                        upload pictures and videos;</li>
                    <li>Allow for loved ones to make a donation to a charity or plant a tree in remembrance;</li>
                </ul>

            </div>
        </div>
    </div>
</section>
<!-- Onescreen Section -->
<section class="memory-code-section card-section solution-sec onescreen-section">
    <div class="container">
        <div class="row memory-code-row">
            <div class="onescreen-wrap">
                <h2 class="section-title">onescreen</h2>
                <div class="section-des">Founded by former executives and alumni from Google, HubSpot, and Wayfair,
                    OneScreen.ai is solving the dilemma of rising online ad costs and declining ROI by unleashing
                    experiences in the real-world. Bringing simplicity to complexity gives marketers more power. The
                    OneScreen.ai way leaves everyone a winner: marketers, businesses, and customers.</div>
            </div>
        </div>
    </div>
</section>

<!-- Advanced search modal start here -->
<!-- Modal -->
<div class="modal fade position-absolute" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" #advacedModal>
    <div class="modal-dialog modal-dialog-centered modal-fullscreen love-modal-top">
        <div class="modal-content advance-modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <h1 class="text-center modal-h1">Search your l<img src="../assets/images/heart-fill.svg"
                        alt="heart-fill" />ved one’s memorial</h1>

                <button type="button" class="btn link-btn btn-border"
                    (click)="isPersonal=true;searchObj=null;selectedState=false"
                    [ngClass]="isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">Personal
                    Details</button>
                <button type="button" class="btn link-btn btn-border memory-code"
                    (click)="isPersonal=false;searchObj=null"
                    [ngClass]="!isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">QR Memory Code
                    Number</button>
                <div class="modal-form">
                    <div class="container">
                        <form class="search-results-form">
                            <ng-container *ngIf="isPersonal">

                                <div class="form-floating form-floating-search person-name-input">
                                    <input type="text" class="form-control right-margin-0" id="name" maxlength="20"
                                        placeholder="Name" [(ngModel)]="searchObj.name" name="name"
                                        autocomplete="off" />
                                    <label for="name">Person’s Name</label>
                                </div>
                                <div class="form-floating form-floating-search birth-input">
                                    <input type="text" class="form-control" id="birthDate" placeholder="Birth Date"
                                        autocomplete="off" aria-label="Birth Date" bsDatepicker
                                        [(ngModel)]="searchObj.birthDate" name="birthDate" readonly
                                        container="birthDate" (click)="addClass('birthDate bs-datepicker theme-green')"
                                        [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}"
                                        (bsValueChange)="onValueChange($event,'dob')" [maxDate]="today" />
                                    <label for=" birthDate">Birth Date</label>
                                </div>
                                <div class="form-floating form-floating-search death-input">
                                    <input type="text" class="form-control right-margin-0" id="deathDate"
                                        placeholder="Day of Passing" autocomplete="off" aria-label="Death Date"
                                        bsDatepicker (bsValueChange)="onValueChange($event,'dod')" [minDate]="maxDate"
                                        [maxDate]="today" (click)="addClass('deathDate bs-datepicker theme-green')"
                                        [(ngModel)]="searchObj.deathDate" name="deathDate" readonly
                                        [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}" />
                                    <label for="deathDate">Day of Passing</label>
                                </div>
                                <div class="form-floating state-input right-margin-0">
                                    <select class="form-select landing-form-select" id="floatingSelect"
                                        [style.color]="selectedState?'#222222':''"
                                        aria-label="Floating label select example" [(ngModel)]="searchObj.state"
                                        (change)="selectedState=true" name="state">
                                        <option [value]="state.id" *ngFor="let state of states" [hidden]="!state?.name">
                                            {{state.name}}</option>
                                    </select>
                                    <label for="floatingSelect" class="state-label"
                                        [ngClass]="selectedState?'removeMar':''">State</label>
                                </div>

                            </ng-container>

                            <div class="form-floating code-number-floating mb-res-15" *ngIf="!isPersonal">
                                <input type="text" class="form-control code-number-input" id="name" maxlength="12"
                                    placeholder="Name" [(ngModel)]="searchObj.memoryCodeNumber" name="memoryCodeNumber"
                                    autocomplete="off" />
                                <label for="name">QR Memory Code Number</label>
                            </div>

                            <div class="search-popup-input search-btn-input">
                                <button type="button" class="btn btn-primary btn-padding search-btn"
                                    (click)="goToSearch('search')" data-bs-dismiss="modal">Search Memorial</button>
                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
