import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient) { }

  onSignIn(payload) {
    return this.http.post(`${environment.apiUrl}login?email=${payload.email}&password=${payload.password}`, '');
  }
  register(payload) {
    return this.http.post(
      `${environment.apiUrl}register?name=${payload.fullName}&email=${payload.email}&password=${payload.password}`,
      '');
  }
  forgotPassword(email) {
    return this.http.post(`${environment.apiUrl}reset-email?email=${email}`, '');
  }
  resetPassword(payload) {
    const urlA = `reset-password?token=${payload.token}&email=${payload.email}`;
    const urlB = `&new_password=${payload.new_password}&confirm_password=${payload.confirm_password}`;
    return this.http.post(`${environment.apiUrl}${urlA}${urlB}`, '');
  }
  verifyUser(payload) {
    return this.http.get(`${environment.apiUrl}verify/${payload.token}?email=${payload.email}`);
  }
  logout() {
    return this.http.post(`${environment.apiUrl}logout`, '');
  }
  changePassword(payload) {
    return this.http.post(`
    ${environment.apiUrl}change-password?current_password=${payload.current_password}&new_password=${payload.new_password}&confirm_password=${payload.confirm_password}`,
      '');
  }
  updateProfile(params) {
    return this.http.put(`${environment.apiUrl}user_update_data?${params}`, '');

  }
}
